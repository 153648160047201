import React from 'react';
import SectionTitle from '../common/SectionTitle';

const FeatureFour = () => {
  return (
    <>
      <section className='feature-section ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-12'>
              <SectionTitle
                // subtitle='Why Choose Us?'
                title='Why Choose Us?'
                // description='  Authoritatively underwhelm excellent methodologies via premium
                //   expertise competitive than open-source imperatives
                //   disseminate.'
                centerAlign
              />
            </div>
          </div>
          <div className='row align-items-center'>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='50'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0 z-2'>
                  <div className='feature-icon d-inline-block bg-primary-soft rounded-circle mb-32'>
                    <i className='fad fa-shield-alt text-primary fa-2x'></i>
                  </div>
                  <h3 className='h5'>  Swift Approval</h3>
                  <p className='mb-0'>
                    We believe in saving your valuable time and quick money compliance by using our upgraded software.
                    After successfully checking your documents, we approve your loan within minutes and in no time it gets transferred to your account!
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 left--40 top--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='100'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0 mt-md-0'>
                  <div className='feature-icon d-inline-block bg-danger-soft rounded-circle mb-32'>
                    <i className='fad fa-comment-alt-smile text-danger fa-2x'></i>
                  </div>
                  <h3 className='h5'>                    Flexible Payment Terms</h3>
                  <p className='mb-0'>
                    We accept payments through simple flexible methods, be it easy pay or bank transfer. It aims to enhance your lifestyle experience without worrying about the scarcity of funds!
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0'>
                  <div className='feature-icon d-inline-block bg-success-soft rounded-circle mb-32'>
                    <i className='fad fa-grin-stars text-success fa-2x'></i>
                  </div>
                  <h3 className='h5'>                    Transparent Pricing</h3>
                  <p className='mb-0'>
                    We offer competitive interest rates and APRs that are fair and all costs are truthfully disclosed to the customer. Our policy is to make life fulfilling for you and your family by adding quality time to each and every moment.


                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6  mt-4'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0'>
                  <div className='feature-icon d-inline-block bg-success-soft rounded-circle mb-32'>
                    <i className='fad fa-shield-alt text-primary fa-2x'></i>
                  </div>
                  <h3 className='h5'>                    Completely Secure Process
                  </h3>
                  <p className='mb-0'>
                    We have separate teams at each level to check the transfer of funds in the most transparent and secure manner to keep away any kind of foul play. Your dream is our dream and we will keep it safe and protected!
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-md-6  mt-4'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0'>
                  <div className='feature-icon d-inline-block bg-success-soft rounded-circle mb-32'>
                    <i className='fad fa-comment-alt-smile text-danger fa-2x'></i>
                  </div>
                  <h3 className='h5'>                    Minimum Documentation</h3>
                  <p className='mb-0'>
                    Paperless Documentation which is 100% online and stress-free. Your personal loan no longer requires visiting the bank and signing bulky bundles of documents. Bharat Loan provides quick, easy and simple digital solutions to all your short-term monetary distress!


                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 mt-4'>
              <div
                className='position-relative'
                data-aos='fade-up'
                data-aos-delay='150'
              >
                <div className='cta-card rounded-custom text-center custom-shadow p-5 bg-white mt-4 mt-lg-0'>
                  <div className='feature-icon d-inline-block bg-success-soft rounded-circle mb-32'>
                    <i className='fad fa-grin-stars text-success fa-2x'></i>
                  </div>
                  <h3 className='h5'>                    Instant Disbursal</h3>
                  <p className='mb-0'>
                    After verification of your documents, we transfer your approved amount instantly. We care about fast delivery and an immediate relief from your financial worries! Advanced technology will be your constant partner as our customer.
                  </p>
                </div>

                <div className='dot-shape-bg position-absolute z--1 right--40 bottom--40'>
                  <img src='assets/img/shape/dot-big-square.svg' alt='shape' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFour;
