const IconBoxData = [
  {
    id: 1,
    classOption: 'bg-primary',
    icon: 'fal fa-layer-group fa-2x text-white',
    title: 'Good Performance',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state .',
  },
  {
    id: 2,
    classOption: 'bg-danger',
    icon: 'fal fa-shield-check fa-2x text-white',
    title: 'Highly Secure',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
  {
    id: 3,
    classOption: 'bg-dark',
    icon: 'fal fa-code fa-2x text-white',
    title: 'Fast Development',
    description:
      'Appropriately grow competitive leadership rather than strategic technically sound processes without state.',
  },
];
const FaqOneData = [
  {
    id: 1,
    faqTitle: 'How does back pricing work?',
    faqDesc:
      'Progressively e-enable collaborative inexpensive supply chains. Efficiently maintain economically methods of empowerment for synergistic sound scenarios.',
  },
  {
    id: 2,
    faqTitle: 'How do I calculate how much price?',
    faqDesc:
      'Globally benchmark customized mindshare before clicks-and-mortar partnerships. Efficiently maintain economically sound scenarios and whereas client-based progressively.',
  },
  {
    id: 3,
    faqTitle: 'Can you show me an example?',
    faqDesc:
      'Dynamically visualize whereas competitive relationships. Progressively benchmark customized partnerships generate interdependent benefits rather sound scenarios and robust alignments.',
  },
];

const TestimonialData1 = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/1.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/2.jpg',
    authorName: 'Joan Dho',
    authorTitle: 'Founder and CTO',
    quoteTitle: 'Best Template for SAAS Company!',
    authorQuote:
      'Dynamically create innovative core competencies with effective best practices promote innovative infrastructures.',
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/3.jpg',
    authorName: 'Ranu Mondal',
    authorTitle: 'Lead Developer',
    quoteTitle: 'It is undeniably good!',
    authorQuote:
      'Rapidiously supply client-centric e-markets and maintainable processes progressively engineer',
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/4.jpg',
    authorName: 'Mr.Rupan Oberoi',
    authorTitle: 'Founder and CEO at Amaara Herbs',
    quoteTitle: 'The Best Template You Got to Have it!',
    authorQuote:
      'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media than ethical',
  },
];

const TestimonialData = [
  {
    id: 1,
    authorImg: 'assets/img/testimonial/1.jpg',
    authorName: 'Rajesh Kumar',
    authorTitle: 'Business Owner',
    quoteTitle: 'Quick and Hassle-free Process!',
    authorQuote:
      'I needed funds urgently for my business expansion, and Svera Loan Web App came to my rescue! Their quick and hassle-free process helped me secure the loan without any difficulties.',
  },
  {
    id: 2,
    authorImg: 'assets/img/testimonial/2.jpg',
    authorName: 'Priya Patel',
    authorTitle: 'Freelancer',
    quoteTitle: 'Flexible Loan Options!',
    authorQuote:
      'Being a freelancer, I value flexibility, and Svera Loan Web App offers just that! Their flexible loan options and easy application process make them my preferred choice for financial assistance.',
  },
  {
    id: 3,
    authorImg: 'assets/img/testimonial/3.jpg',
    authorName: 'Rahul Sharma',
    authorTitle: 'Homeowner',
    quoteTitle: 'Excellent Customer Support!',
    authorQuote:
      'I was impressed by the excellent customer support provided by Svera Loan Web App. They guided me through every step of the loan application process and addressed all my queries promptly.',
  },
  {
    id: 4,
    authorImg: 'assets/img/testimonial/4.jpg',
    authorName: 'Neha Desai',
    authorTitle: 'Student',
    quoteTitle: 'Helped Me Pursue My Dreams!',
    authorQuote:
      'Svera Loan Web App helped me fulfill my educational aspirations by providing the financial support I needed. Their friendly team made the loan application journey smooth and hassle-free.',
  },
];



const TestimonialOneData = [
  {
    id: '#testimonial-tab-1',
    activeClass: 'active',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-1.jpg',
    authorAvatar: 'assets/img/testimonial/1.jpg',
  },
  {
    id: '#testimonial-tab-2',
    title: 'Embarrassed by the First Version.',
    desc: 'Energistically streamline robust architectures whereas distributed mindshare. Intrinsicly leveraother backend growth strategies through 24/365 products. Conveniently pursue revolutionary communities for compelling process improvements.',
    authorName: 'Rupan Oberoi',
    authorDesn: 'Web Designer',
    authorThumb: 'assets/img/testimonial/t-2.jpg',
    authorAvatar: 'assets/img/testimonial/2.jpg',
  },
  {
    id: '#testimonial-tab-3',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-3.jpg',
    authorAvatar: 'assets/img/testimonial/3.jpg',
  },
  {
    id: '#testimonial-tab-4',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-4.jpg',
    authorAvatar: 'assets/img/testimonial/4.jpg',
  },
  {
    id: '#testimonial-tab-5',
    title: 'The Best Template You Got to Have it!',
    desc: 'Globally network long-term high-impact schemas vis-a-vis distinctive e-commerce cross-media infrastructures rather than ethical sticky alignments rather than global. Plagiarize technically sound total linkage for leveraged value media web-readiness and premium processes.',
    authorName: 'Joe Richard',
    authorDesn: 'Visual Designer',
    authorThumb: 'assets/img/testimonial/t-5.jpg',
    authorAvatar: 'assets/img/testimonial/5.jpg',
  },
];

export { IconBoxData, FaqOneData, TestimonialData, TestimonialOneData };
