import React from 'react';
import { Link } from 'react-router-dom';

const FeatureFive = () => {
  return (
    <>
      <section className='app-two-feature ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='section-heading text-center'>
                <h2> 3 Simple Steps To Solve All Your Money-Related Problems</h2>
                <p>
                  We yearn to make this process easier, faster and safer for you in every possible way!
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-10'>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-one me-3 mb-4 mb-md-0'>
                      <i className='far fa-file-edit'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>   Fill Basic Details</h3>
                      <p>
                        We will help you to simplify your financial issues and take control of your debt. To get started,
                         simply fill in your basic information in the form on our website. And let the process begin!
                      </p>
                      <Link
                        to='/single-service'
                        className='link-with-icon text-decoration-none'
                      >
                        View Details <i className='far fa-arrow-right'></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-two me-3 mb-4 mb-md-0'>
                      {/* <i className='far fa-bell'></i> */}
                      <i className="fa-regular fa-square-check"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Submit Documents for Verification</h3>
                      <p>
                        {/* Submit Documents for Verification */}
                        Seam our online KYC documentation effortlessly.
                        Simply upload the following documents from the comfort of your home and send them to our email id at Sveraposting@1122.
                        {/* KYC (PAN, Aadhaar, Voter ID, etc.),
                        3 Months salary slips, last 3 Months bank statement with the current balance */}
                      </p>
                      <Link
                        to='/single-service'
                        className='link-with-icon text-decoration-none'
                      >
                        View Details <i className='far fa-arrow-right'></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-three me-3 mb-4 mb-md-0'>
                      {/* <i className='far fa-pen-nib'></i> */}
                      <i className="fa-solid fa-indian-rupee-sign"></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'> Get Instant Funds</h3>
                      <p>
                      Get Instant Funds, Once approved, your required amount will be transferred directly to your bank account instantly.
                      </p>
                      <Link
                        to='/single-service'
                        className='link-with-icon text-decoration-none'
                      >
                        View Details <i className='far fa-arrow-right'></i>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    <div className='app-two-single-feature-icon box-four me-3 mb-4 mb-md-0'>
                      <i className='far fa-cloud-download-alt'></i>
                    </div>
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Get Started Now</h3>
                      <p>
                        Rapidly explore our plug-and-play loan solutions tailored to your needs.
                         With our cross-unit e-markets, you can quickly find the best loan options available and start your application process hassle-free.
                      </p>
                      <Link
                        // to='/single-service'
                        className='link-with-icon text-decoration-none'
                      >
                        View Details <i className='far fa-arrow-right'></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFive;
