import React from "react";
import PageHeader from "../components/common/PageHeader";
import PageMeta from "../components/common/PageMeta";
import FooterOne from "../layout/Footer/FooterOne";
import Navbar from "../layout/Header/Navbar";
import Layout from "../layout/Layout";
import FooterSocial from "../layout/Footer/FooterSocial";

const Pricing = () => {
    return (
        <Layout>
            <PageMeta title="Term of condition-Svera capital" />
            <Navbar navDark />
            <PageHeader title="Terms & Conditions" desc="" />
            <div className="container">
                {/* <h1 className="mt-5 mb-4">Terms & Conditions</h1> */}
                <p className="mt-5 mb-4">
                    PLEASE REVIEW THE TERMS AND CONDITIONS BEFORE APPLYING!
                    THIS IS A   LEGAL BINDING AGREEMENT BETWEEN SVERA CAPITAL SERVICES LIMITED, AND YOU. BY CLICKING ON "I AGREE" OR "I ACCEPT" OR BY DOWNLOADING, INSTALLING, OR OTHERWISE USING THE SERVICES, YOU SHOW THAT YOU ARE ACCEPTING AND AGREEING TO THE TERMS CONTAINED HEREIN. SO, IF YOU DO NOT AGREE TO THESE TERMS, DO NOT CLICK "I ACCEPT" OR "I AGREE" OR DOWNLOAD, INSTALL OR USE THE SERVICES.

                </p>

                <h4 className="mt-4">DEFINITIONS:</h4>
                <p>
                    "Application, or App", refers to the mobile software developed and designed by the Company to
                    provide Svera Loan services to end users.
                </p>

                <li className="mt-4">
                    "LENDER" refers to a Non-Banking Financial Company or a bank with whom the Company has entered into an agreement for loan sanctioning. The Lender is responsible for approving,processing and  providing  the loan to the Customers, through the Platform. "Company" or "we" shall mean SVERA CAPITAL SERVICES LIMITED, a company incorporated under the Companies Act, 2013 having its registered office at F-179 SECOND FLOOR SUBHASH MARG,C-SCHEME JAIPUR-302001
                </li>
                <li className="mt-4">
                    "Customer" or "You" or "End-User", is any person who uses, views, accesses or downloads the Platform and the Services.

                </li>
                <li className="mt-4">
                    The term "Loan" refers to the financial assistance  which you may apply through the Platform and which is sanctioned and granted by the LENDER,  subject to applicable terms and conditions of the loan agreement. "Loan Agreement" shall mean the loan agreement to be executed between the Lender and the customer (s), which may be applicable from time to time in physical or electronic form for providing the loan.
                </li>
                <li className="mt-4">
                    "Outstanding Amount" shall mean the Loan, interest and fees that you pay to the Lender on the relevant Due    Date (s).
                </li>
                <li className="mt-4">
                    "Platform" shall collectively mean the App and the Website.
                </li>
                <li className="mt-4">
                    "Services" shall mean the services of granting, sanctioning and lending of short-term loans by the
                    LENDER through the Platform.               
                     </li>

                <li className="mt-4">
                    "Online Store" shall mean the Windows Store, Android Google Play, ios App Store, or any other online store or portal where the App is made available by the Company for the End-Users, from time to time.
                </li>
                <li className="mt-4">
                    "Online Store" shall mean the Android Google Play, Windows Store, IOS App Store, or any other online store or portal where the App shall be made available by the Company from time to time to End-Users.
                </li>
                <li className="mt-4">
                    "Third Party Platforms" shall mean the social networking platforms, such as Linkedin, Facebook and
                    other similar platforms.       
                             </li>
                <li className="mt-4">
                    "Website" shall mean www.bharatloan.com, managed and operated by the Company for the provision of Services.
                </li>
                <li className="mt-4">
                    "User Data" shall mean any data, information, document or material submitted to the Company prior to or during the use of the Services.
                </li>
                <h4 className="mt-4"> SERVICES</h4>
                <p>Our Services allow you to apply for a loan if you meet the requirements specified within the app. By using our service, you understand that the Company has been appointed by the LENDER to collect, validate, track your location, confirm and verify the User Data, documents and any other details required by the LENDER to sanction the loan. The LENDER authorizes the Company to collect and store User Data through the mobile application form available on the Platform ("Mobile Application Form").</p>
                <ul>
                    <li className="mt-4">
                        To access the Services you will need to register with the company by logging in through Your Third Party Platform ("User Account"). During the application process, you will be required to provide and upload user data on the mobile application form. User data will include personal information including your name, e-mail address, gender, date of birth, mobile number, password, photograph,contact number, mobile phone information including SMS and browsing history, data and the login-in credentials of Third Party Platforms, such as financial information of bank documents, bank statements, salary slips, bank account no., PAN card, data required for Customer compliances, data from Credit Information Companies, and other important & relevant details ("Personal Information").
                        You agree that the Personal Information will always be accurate, correct and complete. As part of the Services, you authorize us to import your details and personal information spread across third-party platforms.
                        All transactions undertaken by the Company on your behalf shall be based on your express  instructions/consent and shall be strictly on a non-discretionary basis. As part of these terms, you authorize the Company to request your credit information report from one or more Credit Information Companies, as the Company sees fit. Once you verify and upload the User Data and/or other documents and details in the Mobile Application Form, the Company will process the same. On completion of the document verification by the company, you can sanction the loan by the lender subject to suitable eligibility criteria and other terms and conditions stipulated by the lender for sanctioning the loan.
                        The sanctioned loan will be disbursed as per the method given in the mobile application form. You will be required to repay the outstanding amount to the Lender on the respective due date(s) mentioned in the Mobile Application Form.

                    </li>
                    <li className="mt-4">
                        You need to fill and upload the ECS/NACH mandate form/cheque or any other document as required by the Lender. The company may collect physical documents including signatures on
                        documents required for loan approval and processing. On collection of documents by the company, the lender will disburse the loan, subject to the terms and conditions of the loan agreement.

                    </li>
                    <li className="mt-4">
                        The Company reserves the right to track your location ("Track") during the time you are availing the
                        Services, and even after discontinuing, uninstalling or deleting the mobile App. This will be done until all your obligations including payment of the Outstanding Amount(s) to LENDER are fulfilled. You
                        understand and acknowledge that the Company reserves the right to track your location ("Track") during the provision of the Services, and also in the event you cease to use the Services by deleting or uninstalling the mobile app or stop using app or otherwise, until such time as your obligation to pay the LENDER the Outstanding amount ceases to exist.

                    </li>
                    <li className="mt-4">
                        If You Uninstall  or discontinue  using our Services, you  will be still responsible to settle any outstanding amount .
                    </li>

                    <li className="mt-4">
                        Obligation, and liability to repay any Outstanding Amounts:

                        You accept full responsibility for all actions taken under your User Account while using the Services and agree that the Company shall not be held liable for any claims, damages, or disputes arising from the use or misuse of the Services.
                        By accessing the Services, you accept that you are responsible for maintaining the confidentiality of your User Account and for any other related activities. The Company reserves the right to approve or deny your registration for the Services without any explanation. Also, you acknowledge that it's your responsibility to have compatible electronic devices and internet connection that meet the necessary hardware, software, internet connection, and other specifications for the proper functioning of the Platform and the Services.

                        The User Data provided during the registration is stored by the Company for your convenience. You are not required to log in to your User Account, every time, to use or access the Platform.
                        You understand and acknowledge that by accepting these Terms, you authorize us to Track, fetch and use the User Data, including but not limited to your Personal Information, for the purpose of authentication and any updates with regards to your credentials.

                    </li>
                </ul>

                <h3 className="mt-4">LICENSE:</h3>
                <p>

                    License to use the App:    To use the Services, you must download and install the App. You confirm that you are of legal age as defined by applicable laws and are able to understand, enter into, and comply with these Terms. The Company grants you a limited, non-exclusive, nontransferable,  non-sub licensable, and revocable right to download, install, and use the App. The App  is only licensed to you and not sold to you and shall only be used as per these Terms & Conditions

                    Updates/Upgrades: You may be able to subscribe to updates/upgrades for the App through the Online Stores. If you choose not to update/upgrade the App, some features or functionality may not be available to you.

                    Scope of License: You may install, download, access or use the App through the Online Stores on/from mobile phones, tablets, or any other electronic devices.

                    Maintenance & Support: You acknowledge that the Company may offer maintenance and support for
                    the App at its discretion, but is not obligated to provide such services.


                </p>

                <h3 className="mt-4">RESTRICTIONS:</h3>
                <p>
                    You agree not to: use the Platform or the Services for committing fraud, embezzlement, money laundering, or for any unlawful and /or illegal purposes; to reproduce, duplicate, copy, sell, resell or exploit any portion of the App; upload, post, email, transmit or otherwise make available, the Company prohibits any content that is illegal, harmful, threatening, abusive, harassing, tortuous, defamatory,vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable on the Platform; impersonate any person or entity, on the Platform; forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the App; upload, post, email, transmit or otherwise make available any content that you do not have a right to make available under any law; do not upload, post, email, transmit, or otherwise make available on the Platform any content that infringes on any patent, trademark, trade secret, copyright, or other proprietary rights of any party. Additionally, do not make available on the Platform any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation.
                </p>

                <ul>
                    <li className="mt-4">
                        Upload, post, email, transmit or otherwise upload any material containing software viruses or any other computer code, files or programs designed to disrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment made available on the platform. Furthermore, any activity that hinders the normal flow of communication and hinders other users' ability to participate in live discussions, or any attempt to sabotage the Platform or any networks associated with it, or violate any rules and regulations set by these networks is strictly prohibited. Finally, do not intentionally or unintentionally violate any applicable local,
                        state, national, or international laws or regulations having the force of law.

                    </li>

                </ul>

                <h3 className="mt-4">CONTENT AVAILABLE:</h3>
                <p>
                    Please note that while using our Platform we do not make any warranties regarding the available content,including data Files, text, facts and figures, computer software, code, audio files or other sounds, photographs, videos, or other images, on the Platform. The Company shall not be liable  for  any infringing Content, errors or omissions in Content or any loss or damage of any kind incurred as a result of the use of any Content posted, transmitted, linked from, or otherwise accessible through or made available via the Platform. Any information provided on the Platform should not be considered a formal offer,endorsement, or recommendation to purchase or sell any financial products or securities offered by the Company or its affiliates unless otherwise stated in these Terms.
                </p>

                <h3 className="mt-4">PROPRIETARY RIGHTS OF THE COMPANY:</h3>
                <p>
                    By Registering with us, You grant us all rights, title, and interest, including any and all intellectual property rights, in the Content, Platform, Services, logos, trade names, brand names, designs, and any necessary software used in connection with the Platform.

                    There may be proprietary logos, service marks, and trademarks found on the Platform that are
                    owned/used by the Company or others. By displaying them on the Platform, the Company does not
                    grant you a license to use these proprietary logos, service marks, or trademarks. Any unauthorized use of them may violate intellectual property laws.

                    You are entitled to access the Services offered by the Company during the validity of your registration with the Company.You understand and acknowledge that the Company owns the Platform and that these Terms do not
                    transfer ownership, rights, or title in the Platform from the Company to you or any third party.
                </p>

                <h3 className="mt-4"> LINKS TO THIRD PARTY SITES:</h3>
                <ul>
                    <li className="mt-4">
                        The Platform provides some external links to you for your convenience only and the inclusion of any link does not imply any endorsement of the Linked Website by the Company. Your decision and opinion to access or use of Linked Websites is done so entirely at your own risk and the Company shall not be a party or involved in any interactions or transactions between you and the Linked Website.
                    </li>
                    <li className="mt-4">
                        The Platform may have links to external websites that are owned and operated by third parties who have no association with the Platform, referred to as "Linked Websites". The Linked Websites are not under the control of the Company and the Company shall not be responsible for the content of any Linked Websites or any hyperlink contained in a Linked Website and makes no representation or warranty with respect to the content of any such third party sites
                    </li>
                    <li className="mt-4">
                        The Platform may also contain third-party advertisements if any. The display of such advertisements does not in any way imply an endorsement or recommendation by/of the relevant advertiser, its products, or services. You shall independently refer to the relevant advertiser for all information regarding the advertisement and its products and/or services. The Company shall not take any responsibility for any interaction between you and a third party, and will not be held liable for any consequences arising from such interaction.
                    </li>
                </ul>

                <h3 className="mt-4">  ANCILLARY SERVICES:</h3>
                <p>
                    The Platform may provide additional services on the Platform such as chat rooms, blogs, feedback, reviews and other Ancillary Services which may be offered periodically, and are operated by us or third parties who act on our behalf. You are prohibited from utilizing the Ancillary Services or allowing any third party to utilize them for any illegal activities, including but not limited to defamation, abuse, harassment, stalking, threatening, promoting racism, or any other violations of legal rights, such as the rights of privacy of others. Additionally, the Platform may contain advice, opinions, and statements from various professionals, experts and analysts, which the Company does not necessarily endorse or verify the accuracy, or reliability of. It is your responsibility to verify the credibility and usefulness of any such advice, opinions, services, statements, or information provided on the platform, before relying on them The details provided on the Platform are not intended as legal, accounting, tax, financial, investment, or other professional advice and should not be taken as such. It does not offer advice on specific facts or matters. The Company may, at its discretion, update, edit, alter, or remove any information available on the Platform and shall not be held liable for any subsequent claims or losses resulting from such changes. It is important to note that no information on the Platform should be considered as a recommendation for any product or process, and the company gives no representation or warranty, express or implied, that using any of the products or processes on the Platform would not infringe any patents or other rights.

                </p>
                <h3 className="mt-4">TERMINATION:</h3>
                <p>
                    The Company reserves the right to terminate these Terms in the following situations:
                </p>
                <ul>
                    <li className="mt-4">
                        Your violation of any provision of these Terms;                    </li>
                    <li className="mt-4">
                        The Company decides to discontinue offering the Services or to stop operating the Platform;                    </li>
                    <li className="mt-4">
                        The license to use the App expires;
                    </li>
                    <li className="mt-4">
                        The Company is legally required to do so;
                    </li>
                    <li className="mt-4">
                        Non-payment of outstanding amounts).
                    </li>
                    <p className="mt-4">

                        Additionally, the company retain the right to track you even after the termination of these Terms or
                        after you have uninstalled the App until all your obligations, including but not limited to the payment of outstanding amounts), are fulfilled. Upon termination of these Terms, your rights and licenses granted under these Terms shall be canceled and you must immediately stop using the Platform and the Services and repay the outstanding amounts). It should be noted that termination of these terms for any reason whatsoever shall not relieve you and your obligation including but not limited to repayment of the outstanding amount (s).

                    </p>
                </ul>

                <h3 className="mt-4"> DISCLAIMER OF WARRANTIES:</h3>
                <p>
                    By utilizing the services you  understand and agree that the use of Platform is at your own risk and that the Services and the Platform are provided on an "as is" and "as available" basis.
                    The Company makes no representations or warranties of any kind, whether express or implied, including, but not limited to, warranties of merchantability, fitness for a particular purpose, and non-infringement. Any material you download or otherwise obtain through the use of the Platform is done at your own discretion and risk and You will be held accountable for any damage to your computer system, electronic data, or loss of data resulting from downloading any such material. The Company does not provide any warranties other than those explicitly stated in these Terms, regardless of whether the advice or information is oral or written, and obtained through the Company, the Services, or the Platform. The Services are intended for personal, non-commercial use. You are solely responsible for the use, misuse, and improper usage of the Services and the Platform. The Company will not be liable for any damages resulting from the use of the Services that have not been explicitly stated in these Terms. The Company makes no warranty and specifically denies any obligation, that: (a) the Content is complete, exhaustive, accurate, or suitable for your needs; (b) the Platform or Services will meet your needs or will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results obtained from using the Platform or Services will be accurate or reliable.

                </p>

                <h3 className="mt-4">INDEMNITY:</h3>
                <p>

                    According the Agreement You agree to indemnify and hold the company, and its subsidiaries, affiliates, officers, agents, co-branders, other partners, and employees, harmless from any third-party claim or demand, including attorneys' fees, that results from (i) your violation of these Terms, ii) your infringement of other users' rights, (iii) your unauthorized use of the Platform or Services, or (iv) Any violation of applicable laws.

                </p>
                <h3 className="mt-4">FORCE MAJEURE:</h3>
                <p>

                    Notwithstanding anything to the contrary,The Company shall not be held liable for any damage, loss, loss of services of Platform, due to the deficiency in the provision of the Services resulting directly or indirectly from acts of nature, forces, or causes beyond its reasonable control, including, without limitation, internet failures, computer equipment failures, telecommunication equipment failures, change in Reserve Bank of India regulations, or any other government regulations, riots, storms, civil disturbances, electrical failure,, floods.
                </p>

                <h3 className="mt-4">  Cancellation & Refund Policy:</h3>
                <p>
                    By using our Services, you agree to our Cancellation & Refund Policy. Please take a moment to
                    familiarize yourself with the details below, outlining our commitment to fairness and customer-centric
                    practices.
                </p>
                <li className="mt-4">
                     Pre-Disbursement Cancellation: Cancellation is allowed if the loan has been sanctioned but not disbursed.
                </li>
                <li className="mt-4">
                     Cancellation After Disbursement: If the applicant wishes to cancel the service after loan disbursement
                    within 24 hours, the principal amount must be repaid.
                </li>
                <li className="mt-4">
                    Refund of Excess Payments: Any Excess payments made will be refunded within 5-7 days after the reconciliation of loan repayment.
                </li>

                <p className="mt-5">Last Updated: 04/29/2024</p>
            </div>
            {/* <FooterSocial footerLight /> */}
            <FooterOne/>
        </Layout>
    );
};

export default Pricing;
