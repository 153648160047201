import React from "react";
import PageHeader from "../../components/common/PageHeader";
import FooterOne from "../../layout/Footer/FooterOne";
import Navbar from "../../layout/Header/Navbar";
import Layout from "../../layout/Layout";
import FooterTwo from "../../layout/Footer/FooterTwo";
import FooterSocial from "../../layout/Footer/FooterSocial";

const Services = () => {
    return (
        <Layout>
            <Navbar navDark />
            <PageHeader title="Privacy Policy" />
            <div className="container">
                {/* <h1 className="mt-5 mb-4">Privacy Policy</h1> */}
                <p className="mt-5 mb-4">
                    We collect, share, and process Personal Information in accordance with our privacy policy.The collection, sharing, and uploading of Personal Information for the provision of Services is covered in detail in our privacy policy ("Privacy Policy"). 
                    You can find our Privacy Policy here.
                </p>

                <h4 className="mt-4">CHANGE</h4>
                <p>
                    The Company has the right to modify, change, substitute, remove, suspend, or update these Terms or
                    any related information at any time by posting the updated Terms on the Platform. These changes will
                    take effect immediately upon posting. If you continue to use the Services or the Platform after these
                    changes are made, it will be considered your acceptance of the revised Terms
                </p>
                {/* <ul>
                    <li>
                        Contact information (such as name, email address, phone
                        number).
                    </li>
                    <li>
                        Financial information (such as bank account details,
                        credit history).
                    </li>
                    <li>
                        Other information you provide when using the Software.
                    </li>
                </ul> */}

                <h4 className="mt-4">CHOICE OF LAW AND JURISDICTION:</h4>
                <p>
                    This Agreement will be interpreted and governed by the laws of India, regardless of any conflict of laws
                    principles. The parties also agree that the courts in Pune, Maharashtra, India will have exclusive
                    jurisdiction over any disputes related to this Agreement.

                </p>
              
                <h4 className="mt-4">MISCELLANEOUS:</h4>
                <p>
                    By using our services, you agree to the Terms and Loan Agreement Mobile Application Form and Privacy Policy.
                     These Terms constitute the entire understanding between You and the Company with respect to the
                </p>

                <p className="mt-5">Last Updated: 04/29/2024</p>
            </div>
            {/* <FooterSocial footerLight /> */}
            <FooterOne />
        </Layout>
    );
};

export default Services;
