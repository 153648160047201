import React from 'react';
import { Link } from 'react-router-dom';

const HeroEight = () => {
  return (
    <>
      <section
        className='hero-section ptb-120 bg-purple text-white'
        style={{
          background:
            "url('assets/img/app-two-mockup-bg.png')no-repeat center center / cover",
        }}
      >
        <div className='container'>
          <div className='row justify-content-xl-between align-items-center'>
            <div className='col-lg-5 col-xl-5 col-xl-5 col-md-10'>
              <div className='hero-content-wrap mt-5 mt-lg-0 mt-xl-0'>
                <h5 className='text-warning'>  Welcome to Svera App</h5>
                <h1 className='fw-bold display-5'>
                  Get Your Loan Quickly
                </h1>
                <p className='lead'>
                  Incubate your financial goals by accessing quick and reliable loan services. Our platform ensures a seamless experience from application to disbursement, allowing you to obtain funds when you need them the most.

                </p>
                <div className='action-btns mt-5'>
                  <a
                    //  to='/about-us'
                    href="https://user.sveracapital.in/sign-up"
                    target="_blank"
                    rel='noreferrer'
                    className='btn btn-outline-light'>
                    Explore More
                  </a>
                    </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-8 mt-5'>
              <div className='hero-app-img position-relative text-center'>
                <img
                  // src='assets/img/app-two-phone.png'
                  src='assets/img/front_image1.jpeg'
                  alt='app screen'
                  className='img-fluid'
                  style={{ borderRadius: '50%' }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeroEight;
