import React from 'react';
import { Link } from 'react-router-dom';

const OffCanvasMenu = () => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasWithBackdrop"
      >
        <div className="offcanvas-header d-flex align-items-center mt-4">
          <Link
            to="/"
            className="d-flex align-items-center mb-md-0 text-decoration-none"
          >
            <img
              // src="assets/img/logo-color.png"
              src="assets/img/brand-logo/loan_logo.png"
              alt="logo"
              className="img-fluid ps-2"
              style={{ height: "70px" }}
            />
          </Link>
          <button
            type="button"
            className="close-btn text-danger"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="far fa-close"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <ul className="nav col-12 col-md-auto justify-content-center main-menu">
            <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="/"
                role="button"
                // data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Home
              </a>
              <div className="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
                <div className="dropdown-grid rounded-custom width-half">
                  <div className="dropdown-grid-item">
                    <h6 className="drop-heading">Different Home</h6>
                    <Link to="/" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        1
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Saas Company 1</div>
                        <p>
                          It's for <strong>SaaS Software</strong> Company
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-sass-two" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        2
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Saas Company 2</div>
                        <p>
                          Modern <strong>Saas agency</strong>
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-desktop-app" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        3
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Desktop App</div>
                        <p>
                          <strong>Web Software</strong> Company
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-app-landing" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        4
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">App Landing</div>
                        <p>
                          App and <strong>Software</strong> Landing
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-soft-app" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        5
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Software Application</div>
                        <p>
                          Application and
                          <strong>Software </strong>
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-startup" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        6
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Startup Agency</div>
                        <p>
                          Different type of <strong>Agency</strong>&lrm;
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-data-analysis" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        7
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Data Analysis</div>
                        <p>
                          Software & <strong>Data Analysis</strong>&lrm;
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-app-two" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        8
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">App Landing Two</div>
                        <p>
                          App and <strong>Software</strong> Landing
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-nine" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        9
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">IT Solution</div>
                        <p>
                          IT solutions <strong>Software</strong>
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-ten" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        10
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Cyber Security</div>
                        <p>
                          <strong>Cyber Security</strong> landing page
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-eleven" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        11
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Crypto Currency</div>
                        <p>
                          <strong>Crypto Currency</strong> landing page
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-twelve" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        12
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Game Solution</div>
                        <p>
                          <strong>Game Server</strong> landing page
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-thirteen" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        13
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Payment Gateway</div>
                        <p>
                          <strong>Payment Gateway</strong> landing page
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-fourteen" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        14
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Digital Marketing</div>
                        <p>
                          <strong>Digital Marketing</strong> landing page
                        </p>
                      </div>
                    </Link>
                    <Link to="/home-fifteen" className="dropdown-link">
                      <span className="demo-list bg-primary rounded text-white fw-bold">
                        15
                      </span>
                      <div className="dropdown-info">
                        <div className="drop-title">Conference & Event</div>
                        <p>
                          <strong>Conference & Event</strong> landing
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/contact-us" className="nav-link">
                Contact Us
              </Link>
            </li>                                       


            <li>
              <Link
                to="/pivacy-policy"
                className="nav-link"
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms-and-conditions"
                className="nav-link"
              >
                Terms & Conditions
              </Link>
            </li>
         
          </ul>
          <div className="action-btns mt-4 ps-3">
            <a 
              href="https://user.sveracapital.in/login"
             className="btn btn-outline-primary me-2">
              Register
            </a>
            <a 
              href="https://user.sveracapital.in/login"
             className="btn btn-primary">
              Apply For Loan
            </a>
          </div>
  
        </div>
      </div>
    </>
  );
};

export default OffCanvasMenu;
