import React from 'react';
import PageMeta from '../../components/common/PageMeta';
import CtaFive from '../../components/cta/CtaFive';
// import CtaFour from '../../components/cta/CtaFour';
// import FeatureImgContentFive from '../../components/feature-img-content/FeatureImgContentFive';
// import FeatureImgContentSix from '../../components/feature-img-content/FeatureImgContentSix';
import FeatureFive from '../../components/features/FeatureFive';
// import PromoWithVideo from '../../components/promo/PromoWithVideo';
// import TestimonialThree from '../../components/testimonial/TestimonialThree';
import FooterOne from '../../layout/Footer/FooterOne';
import Navbar from '../../layout/Header/Navbar';
import Layout from '../../layout/Layout';
import HeroEight from './HeroEight';
import FeatureFour from '../../components/features/FeatureFour';
import TestimonialTwo from '../../components/testimonial/TestimonialTwo';

const HomeAppTwo = () => {
  return (
    <Layout>
      <PageMeta title='Home - Svera capital' />
      <Navbar navDark />
      <HeroEight />
      <FeatureFive />
      <FeatureFour />

      {/* <FeatureImgContentFive /> */}
      {/* <FeatureImgContentSix /> */}
      {/* <CtaFour /> */}
      {/* <TestimonialThree /> */}
      <TestimonialTwo/>
      {/* <PromoWithVideo /> */}
      <CtaFive />
      <FooterOne  />
    </Layout>
  );
};

export default HomeAppTwo;
